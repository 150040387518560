<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card :title="$t('Add_Product.Update_Variant')" ref="tranHeight">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col md="1">
                <div class="d-flex justify-content-center align-items-center">
                  <b-img fluid :src="data.cover"></b-img>
                </div>
              </b-col>
              <b-col class="my-auto" md="9">
                <h4>
                  <span style="color: #7367f0"
                    >{{ $t("Add_Product.Name") }} :</span
                  >
                  {{ variantData.translation.name }}
                </h4>
                <br />
                <h4>
                  <span style="color: #7367f0"
                    >{{ $t("Add_Product.SKU") }} رمز :
                  </span>
                  {{ variantData.sku }}
                </h4>
                <br />
                <h4>
                  <span style="color: #7367f0">
                    {{ $t("Add_Product.status") }} :
                  </span>
                  {{ variantData.status }}
                </h4>
                <br />
                <h4>
                  <span style="color: #7367f0"> التصنيف : </span>
                  {{ variantData.is_portal ? "بيع  منصة" : "بيع مباشر" }}
                </h4>
                <br />
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col md="6">
                    <b-row>
                      <b-col
                        md="6"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <b-button
                          variant="warning"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-modal.modal-prevent-closing
                          >{{ $t("Add_Product.Add_Price") }}</b-button
                        >
                      </b-col>
                      <b-col
                        md="6"
                        v-show="
                          variant.salePrice > 0 && variant.salePrice != null
                        "
                      >
                        <p>
                          {{
                            variant.is_portal == "Portal"
                              ? $t("Add_Product.Original_Price")
                              : "سعر البائع"
                          }}
                          : {{ variant.original_price }} EGP
                        </p>

                        <p
                          v-show="
                            variant.offer.amount != 0 &&
                            variant.offer.amount != '' &&
                            variant.offer.amount != null
                          "
                        >
                          {{ $t("Add_Product.Offer") }} :
                          <b-badge
                            varaint="primary"
                            v-if="variant.offer.type == 'fixed_price'"
                            >{{ variant.offer.amount }} EGP</b-badge
                          >
                          <b-badge variant="primary" v-else
                            >{{ variant.offer.amount }}%</b-badge
                          >
                        </p>

                        <p v-if="variant.is_portal">
                          {{ $t("Add_Product.Sale_Price") }} :
                          {{ variant.salePrice }} EGP
                        </p>

                        <p>
                          {{
                            variant.is_portal
                              ? $t("Add_Product.Profit")
                              : "عمولة الخدمة"
                          }}
                          : {{ variant.profit }} EGP
                        </p>

                        <p
                          v-if="!variant.is_portal"
                          class="text-dark font-weight-bold"
                        >
                          سعر البيع النهائى :
                          {{
                            parseFloat(variant.original_price) +
                            parseFloat(variant.profit)
                          }}
                          جنيه
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                  <b-col
                    cols="3"
                    class="d-flex justify-content-center align-items-center"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="success()"
                    >
                      <span>{{ $t("Add_Product.Submit") }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-modal
          id="modal-prevent-closing"
          cancel-variant="outline-secondary"
          @ok="handleOk"
          ok-title="أضف"
          cancel-title="أغلق"
          centered
          :title="$t('Add_Product.Add_Price')"
        >
          <validation-observer ref="addPrice">
            <b-form @submit.stop.prevent="addPriceRate">
              <b-row>
                <!-- varint price -->
                <b-col md="6">
                  <b-form-group
                    :label="$t('Add_Product.Price')"
                    label-for="price"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="price"
                      rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                    >
                      <b-form-input
                        v-model="variant.original_price"
                        min="0"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- varint Amount -->
                <b-col md="6" v-if="variant.is_portal">
                  <b-form-group
                    :label="$t('Add_Product.Amount')"
                    label-for="amount"
                  >
                    <b-form-input
                      min="1"
                      type="number"
                      v-model="variant.offer.amount"
                      placeholder="enter amount"
                    />
                  </b-form-group>
                </b-col>
                <b-col v-if="variant.offer.amount > 0" md="12">
                  <b-row>
                    <b-col>
                      <app-collapse
                        id="faq-payment-qna"
                        type="margin"
                        class="mt-0 mb-0"
                      >
                        <app-collapse-item
                          key="variantIndex"
                          title="Click To Customize Offer"
                        >
                          <b-row>
                            <!-- vendors -->
                            <b-col md="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Offer Type"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('Add_Product.Offer_Type')"
                                  label-for="Offer Type"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <v-select
                                    id="Offer-Type"
                                    v-model="offerTypeSelect"
                                    :state="
                                      offerTypeSelect === null ? false : true
                                    "
                                    :dir="
                                      $store.state.appConfig.isRTL
                                        ? 'rtl'
                                        : 'ltr'
                                    "
                                    :options="offerTypeOptions"
                                    :selectable="
                                      (option) =>
                                        !option.value.includes('select_value')
                                    "
                                    label="text"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col md="6">
                              <b-form-group
                                :label="$t('Add_Product.User_Numbers')"
                                label-for="User-Numbers"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="user numbers"
                                  rules="required|regex:^[0]*[1-9][0-9]*$"
                                >
                                  <b-form-input
                                    min="1"
                                    id="User-Numbers"
                                    type="number"
                                    v-model="variant.offer.usernumbers"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="enter user numbers"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <validation-provider
                                #default="{ errors }"
                                name="start date"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('Add_Product.Start_Date')"
                                  label-for="start date"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <flat-pickr
                                    v-model="variant.offer.startdate"
                                    class="form-control"
                                    :config="{
                                      enableTime: true,
                                      dateFormat: 'Y-m-d H:i',
                                    }"
                                  />
                                  <b-form-invalid-feedback
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    {{ errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col md="6">
                              <validation-provider
                                #default="{ errors }"
                                name="end date"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('Add_Product.End_Date')"
                                  label-for="end date"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <flat-pickr
                                    v-model="variant.offer.enddate"
                                    class="form-control"
                                    :config="{
                                      enableTime: true,
                                      dateFormat: 'Y-m-d H:i',
                                    }"
                                  />
                                  <b-form-invalid-feedback
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    {{ errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </app-collapse-item>
                      </app-collapse>
                    </b-col>
                  </b-row>
                  <!-- error handelr -->
                  <b-alert
                    v-height-fade.appear
                    fade
                    :show="showDismissibleAlert2"
                    @dismissed="showDismissibleAlert2 = false"
                    variant="danger"
                  >
                    <h4 class="alert-heading">Alert</h4>
                    <div class="alert-body">
                      <ul v-for="(values, index) in errors_back" :key="index">
                        <li v-for="(value, valIndex) in values" :key="valIndex">
                          {{ value }}
                        </li>
                      </ul>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-card>
    </validation-observer>

    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import { required } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
  },
  mixins: [heightTransition],
  data() {
    return {
      errors_back2: [],
      showDismissibleAlert2: false,
      required,
      errors_back: [],
      showDismissibleAlert: false,
      vendorCommissionRate: null,
      id: 0,
      offerTypeSelect: {
        text: "",
        value: "",
      },
      offerTypeOptions: [
        {
          text: "Fixed Price",
          value: "fixed_price",
        },
        {
          text: "Percentage",
          value: "percentage",
        },
      ],
      localeOptions: [
        {
          value: "en",
          text: "English",
        },
        {
          value: "ar",
          text: "Arabic",
        },
      ],
      tranLocaleSelect: [],
      variantData: null,
      variant: {
        is_portal: null,
        offer: {
          amount: null,
          enddate: null,
          startdate: null,
          type: null,
          usernumbers: null,
        },
        original_price: null,
        salePrice: null,
        amountPriceDis: true,
        offerHide: false,
      },
      data: null,
      updatePriceUrl: null,
    };
  },
  computed: {},
  async created() {
    this.id = this.$route.params.id;
    await this.getVariantData(this.id);
  },
  methods: {
    success() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const sendData = { ...this.variant };
          if (this.variant.offer != null) {
            if (
              this.variant.offer.amount == "" ||
              this.variant.offer.amount == null
            ) {
              delete sendData.offer;
            }
          }

          if (this.variant.is_portal) {
            this.updatePriceUrl = "variants/" + this.id + "/update";
          } else {
            this.updatePriceUrl = "variants/" + this.id + "/instore/update";
          }
          axios
            .put(this.updatePriceUrl, sendData)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    async getVariantData(id) {
      await axios
        .get("variants/" + id + "/showLocale")
        .then((result) => {
          const data = result.data.data;
          this.data = data;
          if (!data.product.vendor.can_update_price) {
            this.$router.back(1);
          }
          this.variant.is_portal = data.is_portal;
          this.variant.profit = data.profit;
          if (data.offer != null) {
            if (data.offer.type == "percentage") {
              this.offerTypeSelect.value = "percentage";
              this.offerTypeSelect.text = "Percentage";
            } else {
              this.offerTypeSelect.value = "fixed_price";
              this.offerTypeSelect.text = "Fixed Price";
            }
          }

          if (data.is_portal) {
            this.variant.original_price = data.original_price;
          } else {
            this.variant.original_price = data.original_price - data.profit;
          }
          this.variant.salePrice = data.sale_price;
          if (data.offer != null) {
            this.variant.offer.amountPriceDis = false;
            this.variant.offerHide = true;
            this.variant.offer.amount = data.offer.amount;
            this.variant.offer.enddate = data.offer.enddate;
            this.variant.offer.startdate = data.offer.startdate;
            this.variant.offer.type = data.offer.type;
            this.variant.offer.usernumbers = data.offer.usernumbers;
          } else {
            this.variant.offer.amountPriceDis = false;
            this.variant.offerHide = false;
          }

          this.variantData = data;

          // this.vendorCommissionRate = data.product.vendor.commission.rate
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.addPriceRate();
    },
    async addPriceRate() {
      this.showDismissibleAlert2 = false;
      const isValid = await this.$refs.addPrice.validate();
      if (isValid) {
        axios
          .post("products/sale-price/get", {
            original_price: this.variant.original_price,
            product_type: "new",
            item_id: this.variantData.product.item.id.toString(),
            offer_type:
              this.offerTypeSelect == null ? null : this.offerTypeSelect.value,
            offer_amount:
              this.variant.offer.amount == 0 ? null : this.variant.offer.amount,
            vendor_id: this.variantData.product.vendor.id.toString(),
            is_portal: this.variant.is_portal,
          })
          .then((result) => {
            this.variant.offer.amount =
              this.variant.offer.amount == 0 ? null : this.variant.offer.amount;
            this.variant.offer.type =
              this.offerTypeSelect == null ? null : this.offerTypeSelect.value;
            this.variant.original_price = this.variant.original_price;
            this.variant.offer.startdate =
              this.variant.offer.startdate == null
                ? null
                : this.variant.offer.startdate;
            this.variant.offer.enddate =
              this.variant.offer.enddate == null
                ? null
                : this.variant.offer.enddate;
            this.variant.offer.usernumbers =
              this.variant.offer.usernumbers == null
                ? null
                : this.variant.offer.usernumbers;
            this.variant.profit = result.data.profit;
            this.variant.salePrice = result.data.sale_price;
            this.$nextTick(() => {
              this.$bvModal.hide("modal-prevent-closing");
            });
          })
          .catch((err) => {
            this.errors_back2.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back2.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back2 = err.response.data.data;
              }
              this.showDismissibleAlert2 = true;
            } else {
              this.errors_back2 = [];
              this.errors_back2.push({
                error: "internal server error",
              });
              this.showDismissibleAlert2 = true;
            }
          });
      } else {
      }
    },
    // show offer in variants
    showOffer() {
      const oP = parseFloat(this.variant.original_price);
      const sP = parseFloat(this.varian.salePrice);
      if (
        this.variant.salePrice > 0 &&
        this.variant.salePrice != "" &&
        this.variant.salePrice != null &&
        oP >= sP
      ) {
        this.variant.offerHide = true;
        this.variant.offer.amount =
          this.variant.original_price - this.variant.salePrice;
        this.variant.type = "fixed_price";
      } else {
        this.variant.offerHide = false;
        (this.variant.offer.amount = ""),
          (this.variant.type = ""),
          (this.variant.startdate = "");
        this.variant.enddate = "";
        this.variant.usernumbers = "";
      }
    },
    // when change amount of offer
    changeAmount() {
      const oP = parseFloat(this.variant.original_price);
      const aP = parseFloat(this.variant.offer.amount);
      if (
        this.variant.offer.amount > 0 &&
        this.variant.offer.amount != "" &&
        this.variant.offer.amount != null &&
        oP > aP
      ) {
        this.variant.offerHide = true;
        let price =
          parseFloat(this.variant.original_price) -
          parseFloat(this.variant.offer.amount);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.variant.salePrice = price + profit;
        this.variant.type = "fixed_price";
      } else {
        (this.variant.offer.amount = ""), (this.variant.type = "");
        this.variant.startdate = "";
        this.variant.enddate = "";
        this.variant.usernumbers = "";
        this.variant.offerHide = false;
        let price = parseFloat(this.variant.original_price);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.variant.salePrice = price + profit;
      }
    },
    // when change original price of vaiants
    changeOP() {
      if (
        this.variant.original_price > 0 &&
        this.variant.vriginal_price != "" &&
        this.variant.original_price != null
      ) {
        this.variant.offer.amountPriceDis = false;
        let price = parseFloat(this.variant.original_price);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.variant.salePrice = price + profit;
        this.variant.type = "fixed_price";
      } else {
        this.variant.offer.amountPriceDis = true;
        (this.variant.offer.amount = ""),
          (this.variant.offer.amount = ""),
          (this.variant.type = ""),
          (this.variant.startdate = "");
        this.variant.enddate = "";
        this.variant.usernumbers = "";
        this.variant.salePrice = "";
        this.variant.offer.amountPriceDis = false;
        let price = parseFloat(this.variant.original_price);
        let profit = (price * this.vendorCommissionRate) / 100;
        this.variant.salePrice = price + profit;
      }
    },
    // translation repeated form functions
    translationrepeateAgain() {
      // this.refheightForm = this.$refs.translationForm
      this.variant.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      });
      this.tranLocaleSelect.push({
        //  id: this.nextTodoId += this.nextTodoId,
      });
    },
    translationremoveItem(index) {
      if (this.variant.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
          title: "Alert",
          size: "sm",
          okVariant: "danger",
          okTitle: "Accept",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        });
      } else {
        this.variant.translations.splice(index, 1);
        this.tranLocaleSelect.splice(index, 1);
      }
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.repeater-form {
  transition: 0.35s height;
}
</style>
